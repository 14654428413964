import React from "react";

//Styles
import "./Input.scss";

const Input = ({
    className = "",
    text,
    name,
    value,
    type = "text",
    placeholder,
    onChange,
    required,
    icon,
    id,
    ref,
    
}) => {
    return (
        <div className={`Input ${className}`}>
            {text && <span className="title-input">{text}</span>}
            <div
                className={`container-input ${
                    type === "date" ? "classDate" : ""
                }`}
            >
                {icon && (
                    <div className="cnt-img">
                        <img src={icon} alt="" className="icon-in" />
                    </div>
                )}
                <input
                    className={`cs-input ${icon ? "none-border" : ""}`}
                    type={type}
                    ref={ref}
                    id={id}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    required={required}
                />
            </div>
        </div>
    );
};
export default Input;
