import React, { useState } from "react";

import "./Videos.scss";
import Button from "../../components/atoms/Button/Button";
import Input from "../../components/atoms/Input/Input";

// Assets
import Seacrg_ from "../../assets/icons/BUSCAR.png";
import CardModal from "../../components/atoms/CardModal/CardModal";
import TextArea from "../../components/atoms/TextArea/TextArea";

const Videos = () => {
    const [openVideoForm, setOpenVideoForm] = useState(false);

    const video = [
        {
            id: 1,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 1",
            description: "This is a video 1",
        },
        {
            id: 2,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 2",
            description: "This is a video 2",
        },
        {
            id: 3,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 3",
            description: "This is a video 3",
        },
        {
            id: 4,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 4",
            description: "This is video 4",
        },
        {
            id: 5,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 5",
            description: "This is video 5",
        },
        {
            id: 6,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 6",
            description: "This is video 6",
        },
        {
            id: 7,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 7",
            description: "This is video 7",
        },
        {
            id: 8,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 8",
            description: "This is video 8",
        },
        {
            id: 9,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 9",
            description: "This is video 9",
        },
        {
            id: 10,
            url: "https://www.youtube.com/watch?v=4UZrsTqkcW4",
            title: "Video 10",
            description: "This is video 10",
        },
        {
            id: 11,
            url: "https://www.youtube.com/watch?v=Bv4yjbyuuUg",
            title: "Video 11",
            description: "This is video 11",
        },
    ];

    const [videoData, setVideoData] = useState({
        title: "",
        description: "",
        url: "",
    });

    const getEmbedUrl = (url) => {
        const videoId = url.split("v=")[1]?.split("&")[0];
        return `https://www.youtube.com/embed/${videoId}`;
    };

    const eventChange = (e) => {
        const { name, value } = e.target;

        setVideoData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUpVideo = (e) => {
        e.preventDefault();

        console.log(videoData);
    };

    return (
        <>
            <div className="Videos_">
                <div className="v-header">
                    <h5 className="v-title">Videos</h5>
                    <div className="v-header_right">
                        <Input placeholder={"Buscar tutorial"} icon={Seacrg_} />
                        <Button
                            text={"Subir Video"}
                            icon={false}
                            className="v-up_video degrad"
                            onClick={() => setOpenVideoForm(true)}
                        />
                    </div>
                </div>
                <div className="v-container">
                    {video.map((v, idx) => (
                        <div key={idx} className="v-card">
                            <iframe
                                className="v-iframe"
                                src={getEmbedUrl(v.url)}
                                title={v.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation"

                            ></iframe>

                            <p>{v.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {openVideoForm && (
                <CardModal
                    setStatusModal={setOpenVideoForm}
                    className={"v-modal-form"}
                >
                    <form onSubmit={handleUpVideo} className="v-form">
                        <h5 className="v-formTitle">Subir Video</h5>
                        <Input
                            placeholder={"Titulo"}
                            name={"title"}
                            onChange={eventChange}
                        />
                        <TextArea
                            name={"description"}
                            placeholder={"Descripción"}
                            onChange={eventChange}
                        />
                        <Input
                            placeholder={"URL"}
                            name={"url"}
                            onChange={eventChange}
                        />
                        <Button
                            text={"Subir"}
                            type={"button"}
                            className="v_video degrad"
                            onClick={handleUpVideo}
                        />
                    </form>
                </CardModal>
            )}
        </>
    );
};

export default Videos;
