import React, { useState } from "react";

//Styles
import "./BusinessPayments.scss";

//Assets
import CardView from "../../components/molecules/CardView/CardView";
import Input from "../../components/atoms/Input/Input";
import Table from "../../components/organisms/Table/Table";

//JSON
import Columns from "../../assets/jsons/BusinessPayments.json";
import ContainerTable from "../../components/atoms/ContainerTable/ContainerTable";
import ToggleTokens from "../../components/atoms/ToggleTokens/ToggleTokens";

const BusinessPayments = () => {
    const handleSearch = (e) => {
        e.preventDefault();
    };

    const Rounded = ({ data }) => {
        return (
            <span
                className="paym-backgrd"
                style={{
                    backgroundColor:
                        data <= 1
                            ? "red"
                            : data <= 5
                            ? "orange"
                            : data <= 10
                            ? "yellow"
                            : "green",
                    color: data >= 6 && data <= 10 ? "#000" : "#fff",
                }}
            >
                {data}
            </span>
        );
    };

    const data = [
        {
            business_name: "Tech Innovators",
            owner_name: "Carlos López",
            creation_date: "2022-01-15",
            membership: "Gold",
            day: <Rounded data={10} />,
            last_payment: "2024-07-15",
        },
        {
            business_name: "Green Earth",
            owner_name: "Laura Martínez",
            creation_date: "2021-05-22",
            membership: "Silver",
            day: <Rounded data={13} />,
            last_payment: "2024-06-20",
        },
        {
            business_name: "Digital Solutions",
            owner_name: "Andrés Fernández",
            creation_date: "2020-11-10",
            membership: "Platinum",
            day: <Rounded data={3} />,
            last_payment: "2024-08-01",
        },
        {
            business_name: "Healthy Life",
            owner_name: "María García",
            creation_date: "2019-03-30",
            membership: "Gold",
            day: <Rounded data={1} />,
            last_payment: "2024-07-25",
        },
        {
            business_name: "Tech World",
            owner_name: "Pedro Sánchez",
            creation_date: "2018-08-15",
            membership: "Bronze",
            day: <Rounded data={6} />,
            last_payment: "2024-07-10",
        },
    ];

    const [toggles, setToggles] = useState([
        {
            id: 1,
            text: "Rojos",
            state: false,
        },

        {
            id: 2,
            text: "Naranjas",
            state: false,
        },
        {
            id: 3,
            text: "Amarillos",
            state: false,
        },
        {
            id: 4,
            text: "Verdes",
            state: false,
        },
    ]);


    const handleToggleChange = (id) => {
        setToggles((prevToggles) =>
            prevToggles.map((toggle) =>
                toggle.id === id ? { ...toggle, state: !toggle.state } : toggle
            )
        );
    };

    return (
        <CardView>
            <div className="BusinessPayments">
                <div className="b-header">
                    <div className="b-cnt-toggle">
                        <div className="b-text-t">
                            Selecciona las opciones para filtrar resultados
                        </div>
                        <div className="b-compo-toggle">
                            {toggles.map((com, idx) => (
                                <ToggleTokens
                                    id={com.id}
                                    key={idx}
                                    text={com.text}
                                    isChecked={com.state}
                                    setIsChecked={() =>
                                        handleToggleChange(com.id)
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <form onSubmit={handleSearch} className="b-form">
                        <Input
                            placeholder={"Negocio"}
                            type="search"
                            className="b-input"
                        />
                        <button type="submit" className="b-sea">
                            Buscar
                        </button>
                    </form>
                </div>
                <ContainerTable>
                    <Table columns={Columns} data={data} />
                </ContainerTable>
            </div>
        </CardView>
    );
};

export default BusinessPayments;
