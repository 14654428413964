import React from "react";
const Faq = ({preguntas}) => {
    return (
        <>
            <div className="titulo">PREGUNTAS FRECUENTES</div>
            <div className="contenedorDeProguntas">
                <div className="contenedor">
                    {preguntas.map((item, key) => {
                        return (
                            <>
                                {key <=
                                    (preguntas.length - 1) / 2 ? (
                                    <>
                                        <div className="tituloPregunta">
                                            {item.pregunta}
                                        </div>
                                        <div className="respuesta">
                                            {item.respuesta}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        );
                    })}
                </div>
                <div className="contenedor">
                    {preguntas.map((item, key) => {
                        return (
                            <>
                                {key >=
                                    (preguntas.length - 1) / 2 ? (
                                    <>
                                        <div className="tituloPregunta">
                                            {item.pregunta}
                                        </div>
                                        <div className="respuesta">
                                            {item.respuesta}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
        </>
    )
}
export default Faq