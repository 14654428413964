import React, { useState } from "react";
import "./Manuals.scss";
import manualesIcon from "../../assets/icons/manuales/icono_manuales.svg";
import faqIcon from "../../assets/icons/manuales/icono_faq.svg";
import videosIcon from "../../assets/icons/manuales/icono_videos.svg";
import comoPagarIcon from "../../assets/icons/manuales/icono_como_pagar.svg";
import atencionIcon from "../../assets/icons/manuales/icono_atenciom_person.svg";
import ManualCategoryCircle from "../../components/atoms/ManualCategoryCircle/ManualCategoryCircle";
// import Select from "../../components/atoms/Select/Select";
// import Button from "../../components/atoms/Button/Button";
import CardView from "../../components/molecules/CardView/CardView";
// import Input from "../../components/atoms/Input/Input";
// import buscarIcon from "../../assets/icons/BUSCAR.png";
import SeccionManuales from "./SeccionManuales";
import Videos from "../Videos/Videos";
import Faq from "../Faq/Faq";
import ComoPagarMembresia from "../ComoPagarMembresia/ComoPagarMembresia";
import AtencionPersonalizada from "../AtencionPersonalizada/AtencionPersonalizada";

const Manuals = () => {
    // const [selectValue, setSelectValue] = useState();
    // const selectOptions = [
    //     { name: "opcion1", code: 1 },
    //     { name: "opcion2", code: 2 },
    //     { name: "opcion3", code: 3 },
    //     { name: "opcion4", code: 4 },
    // ];;
    const preguntas = [
        {
            pregunta: "¿Dondé se encuentra el “menu de la app”?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 2?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 3?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 4?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 5?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 6?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 7?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
        {
            pregunta: "¿Pregunta 8?",
            respuesta:
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ",
        },
    ];
    const pdfs = [
        {
            id: 1,
            titulo: "Pago1",
            categoria: "PAGO DE MEMBRESIA",
            descripcion: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
            link: "#"
        },
        {
            id: 2,
            titulo: "Pago2",
            categoria: "PAGO DE MEMBRESIA",
            descripcion: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
            link: "#"
        },
        {
            id: 3,
            titulo: "Pago3",
            categoria: "PAGO DE MEMBRESIA",
            descripcion: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
            link: "#"
        },
        {
            id: 4,
            titulo: "Pago4",
            categoria: "PAGO DE MEMBRESIA",
            descripcion: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
            link: "#"
        },
        {
            id: 1,
            titulo: "Pago1",
            categoria: "PAGO DE MEMBRESIA",
            descripcion: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
            link: "#"
        },
    ]
    const [seccion, setSeccion] = useState('manual');

    return (
        <CardView>
            <div className="Manuals">
                {/* <div className="buscador">
                    <div className="contenedor">
                        <Input text={"Buscar"} icon={buscarIcon}></Input>
                    </div>
                </div> */}
                <div className="containerCategories">
                    {/* <ManualCategoryCircle
                        img={manualesIcon}
                        title="CATEGORÍAS"
                        onClick={() => {
                            setSeccion(null);
                        }}
                    ></ManualCategoryCircle> */}
                    <ManualCategoryCircle
                        img={manualesIcon}
                        title="MANUALES"
                        onClick={() => {
                            setSeccion("manual");
                        }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={faqIcon}
                        title="FAQ"
                        onClick={() => {
                            setSeccion("faq");
                        }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={videosIcon}
                        title="VIDEOS"
                        onClick={() => {
                            setSeccion("videos");
                        }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={comoPagarIcon}
                        title="COMO PAGAR MEMBRESIAS"
                        onClick={() => {
                            setSeccion("membresias");
                        }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={atencionIcon}
                        title="ATENCIÓN PERSONALIZADA"
                        onClick={() => {
                            setSeccion("atencion");
                        }}
                    ></ManualCategoryCircle>
                </div>
                {/* {
                    seccion === null &&
                    <>
                        <div className="createCategory">
                            <div className="card">
                                <div className="title">CREAR CATEGORÍA</div>
                                <div className="select-container">
                                    <Select
                                        className="select"
                                        setValue={setSelectValue}
                                        value={selectValue}
                                        options={selectOptions}
                                    ></Select>
                                </div>
                                <div className="buttonCrear">
                                    <Button
                                        className="degrad buttoncREAR"
                                        text={"CREAR"}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </>

                } */}
                {seccion === "manual" &&
                    <div className="manualesContainer">
                        <div className="titulo">Manuales</div>
                        <SeccionManuales></SeccionManuales>
                    </div>}
                {seccion === "faq" && <Faq preguntas={preguntas}></Faq>}
                {seccion === "videos" && <Videos />}
                {seccion === "membresias" &&
                    <>
                        <div className="titulo">COMO PAGAR MEMBRESIAS</div>
                        <ComoPagarMembresia pdfs={pdfs} />
                    </>
                }
                {seccion === "atencion" &&
                    <>
                       
                        <AtencionPersonalizada />
                    </>
                }
            </div>
        </CardView>
    );
};
export default Manuals;
