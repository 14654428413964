import React from "react";

import "./TextArea.scss";

const TextArea = ({
    className = ".",
    text,
    name,
    value,
    placeholder,
    onChange,
}) => {
    return (
        <div className="TextArea">
            {text && <span className="title-textarea">{text}</span>}
            <textarea
                className={`cs-textarea ${className}`}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
};

export default TextArea;
