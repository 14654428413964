import React from "react";

import "./ToggleTokens.scss";

const ToggleTokens = ({ isChecked, setIsChecked, id, text = "Example" }) => {
    const handleToggleChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div className="Container-toggleTokens">
            <input
                type="checkbox"
                id={id ? id : "toggle-check"}
                className="toggleTokens"
                checked={isChecked}
                onChange={handleToggleChange}
            />
            <label
                htmlFor={id ? id : "toggle-check"}
                className={`toggle-label ${isChecked ? "checked-label" : ""}`}
            >
                {text}
            </label>
        </div>
    );
};

export default ToggleTokens;
