import React, { useEffect, useState } from "react";
import "./CardModal.scss";

const CardModal = ({ children, className, setStatusModal }) => {
    const [smoothCenter, setSmoothCenter] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSmoothCenter(true);
        }, 500);

        // Limpieza para evitar posibles problemas al desmontar
        return () => clearTimeout(timeoutId);
    }, []);

    // Nos aseguramos de que el modal se oculte de forma suave
    const handleHidden = () => {
        setSmoothCenter(false);

        const timeoutId = setTimeout(() => {
            setStatusModal(false);
        }, 500);

        // Limpieza para evitar posibles problemas al desmontar
        return () => clearTimeout(timeoutId);
    };

    return (
        <div className="CardModal">
            <div
                className={`m-container ${
                    smoothCenter ? "m-show" : "m-hide"
                } ${className}`}
            >
                <button className="m-btn-close" onClick={handleHidden}>
                    ❌
                </button>
                {children}
            </div>
        </div>
    );
};

export default CardModal;
