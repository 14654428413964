import React from "react";
import './AtencionPersonalizada.scss'
import emailIcon from '../../assets/icons/manuales/icono_mail.png'
import wsIcon from '../../assets/icons/manuales/icono-whasapp.png'
import Input from "../../components/atoms/Input/Input";
import Button from "../../components/atoms/Button/Button";
const AtencionPersonalizada = () => {
    return (
        <div className="AtencionPersonalizada">
            <div className="columnasPersonalizadas">
                <div className="col1">
                    <div className="titulo">ATENCIÓN PERSONALIZADA</div>
                    <img src={wsIcon} alt="" />
                </div>
                <div className="col2">
                    <img src={emailIcon} alt="" />
                    <Input text={"Nombre Negocio"}></Input>
                    <Input text={"Nombre del Encargado"}></Input>
                    <Input text={"E-mail"}></Input>
                    <Input text={"Número de Contacto"}></Input>
                    <Input text={"Explicación detallada del asunto."} placeholder={"¿Cómo te podemos apoyar?"}></Input>
                    <div className="containerButonts">
                        <Button text={"Cancelar"}></Button>
                        <Button className="degrad" text={"Enviar"}></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AtencionPersonalizada