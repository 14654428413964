import React, { useState } from "react";

// Styles
import "./Login.scss";

//Components
import CardBack from "../../components/atoms/CardBack/CardBack";
import Input from "../../components/atoms/Input/Input";
import Button from "../../components/atoms/Button/Button";
import InputPassword from "../../components/atoms/InputPassword/InputPassword";

//Assets
import MovilImage from "../../assets/icons/icono_login.png";
import IconUsername from "../../assets/icons/icono_perfil_login.png";
import IconPassword from "../../assets/icons/icono_password-login.png";
import { NavLink } from "react-router-dom";
import { encriptar } from "../../utiles/encriptacion";

const Login = () => {
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });

    const handleChangeDatas = (e) => {
        const { name, value } = e.currentTarget;
        setCredentials((prevent) => ({
            ...prevent,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(typeof credentials);
    };

    const autenticar = () => {
        const key = process.env.REACT_APP_CLAVE_INCRIPTACION;
        const auth = encriptar("true", key);
        localStorage.setItem("auth", auth);
        // navigate("/")
        // window.location.reload();
        window.location.href = '/inicio'; // Cambiar la ruta
    };
    return (
        <CardBack className="LoginView">
            <div className="container-form">
                <div className="cnt-header">
                    <img src={MovilImage} alt="" className="movil-image" />
                    <h3>Iniciar sesion en APP Restaurant</h3>
                </div>
                <form className="form_" onSubmit={handleSubmit}>
                    <div className="cnt-inputs">
                        <div className="cnt-user">
                            <Input
                                type="email"
                                name="email"
                                className="input-username"
                                placeholder="Correo electrónico"
                                onChange={handleChangeDatas}
                            />
                            <img
                                src={IconUsername}
                                alt=""
                                className="icons-form"
                            />
                        </div>
                        <div className="cnt-password">
                            <InputPassword
                                name="password"
                                className="input-pass"
                                placeholder="Contraseña"
                                onChange={handleChangeDatas}
                            />
                            <img
                                src={IconPassword}
                                alt=""
                                className="icons-form"
                            />
                            {/* <div className="cnt-nav">
                                <NavLink
                                    to={"recover-password"}
                                    className={"forgot-pass"}
                                >
                                    <span>¿Olvidaste tu contraseña?</span>
                                </NavLink>
                            </div> */}
                        </div>
                    </div>
                    <Button
                        text="Ingresar"
                        className="degrad"
                        type="button"
                        onClick={() => {
                            autenticar();
                        }}
                    />
                </form>
                <div className="container-footer">
                    <NavLink className={"block-nav"}>
                        <span className="s-clic">
                            ¿Quieres probar la aplicación?
                        </span>
                        <span className="tooltip_">Haz clic aquí</span>
                    </NavLink>
                </div>
            </div>
        </CardBack>
    );
};

export default Login;
